import React, { useMemo } from 'react'
import { cacheExchange, createClient, fetchExchange, Provider } from 'urql'
import { useSession } from 'hooks'

interface GraphqlClientProps {
    children: React.ReactNode
}

const GraphqlClient: React.FC<GraphqlClientProps> = ({ children }) => {
    const { session } = useSession()
    const client = useMemo(() => {
        const graphUrl = process.env.REACT_APP_GRAPHQL_URL
        if (!graphUrl) {
            throw new Error('Invalid client url')
        }
        return createClient({
            url: graphUrl,
            fetchOptions: {
                headers: {
                    Authorization: `Bearer ${session?.auth?.token}`
                }
            },
            exchanges: [cacheExchange, fetchExchange]
        })
    }, [session?.auth?.token])

    return <Provider value={client}>{children}</Provider>
}

export default GraphqlClient
