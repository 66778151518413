import { Button } from '@alejosdev/components'
import { useCallback } from 'react'
import { ActivityFollowUpFormType } from 'types/form-types/activities.form-types'
import useMutateActivityFollowUp from 'graphql/use-mutate-activity-follow-up'

interface RenderResumeProps {
    resume?: ActivityFollowUpFormType | null
    onSaved?: () => void
}

const RenderResume: React.FC<RenderResumeProps> = ({ resume, onSaved }) => {
    const { create, loading } = useMutateActivityFollowUp()
    const handleSent = useCallback(async () => {
        if (!resume) return false
        const response = await create(resume)
        if (response) {
            onSaved?.()
        }
    }, [create, onSaved, resume])
    if (!resume) return null
    const { started_date, time_spent, description } = resume
    return (
        <div>
            <p>Started at: {started_date}</p>
            <p>Date: {started_date}</p>
            <p>Time: {time_spent}</p>
            <p>Description: {description}</p>
            <Button onClick={handleSent} loading={loading}>
                Confirm
            </Button>
        </div>
    )
}

export default RenderResume
