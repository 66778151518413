import { ActivityScheme } from '../../types/schemes/activities.scheme'
import useGqlQuery, { GqlOptionsType } from '../useGqlQuery'
import { GET_ACTIVITIES_QUERY } from './queries'

/**
 * Hook to get the activities list
 * @returns
 */
const useGetActivities = (config?: GqlOptionsType) => {
    const { variables = {} } = config || {}
    return useGqlQuery<typeof variables, ActivityScheme[]>(GET_ACTIVITIES_QUERY, {
        variables
    })
}

export default useGetActivities
