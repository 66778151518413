import { EssentialActivitiesChart } from '../../../charts/activities'

interface DailyLiveReportProps {}

/**
 * Component documentation...
 * @returns React.FC
 */
const DailyLiveReport: React.FC<DailyLiveReportProps> = () => {
    return (
        <div>
            <EssentialActivitiesChart />
        </div>
    )
}

export default DailyLiveReport
