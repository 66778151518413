import { useEffect } from 'react'

type KeyboardKeyType = 'Enter' | 'Escape'

const useInputKeyListenerEffect = (
    ref: any,
    callback: () => void,
    key: KeyboardKeyType,
    deps?: any[],
    eventHandler?: 'keyup' | 'keydown' | 'keytyped'
) => {
    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            e.key === key && callback()
        }
        const node = ref?.current
        if (node) {
            node.addEventListener(eventHandler || 'keyup', handler)
        }

        return () => {
            if (node) {
                node.removeEventListener(eventHandler || 'keyup', handler)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), ref?.current])
}

export default useInputKeyListenerEffect
